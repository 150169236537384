import type { GeoJSON } from '@indomita-website/search/types';
import mainFeaturesSprite from '@pepita-fe/sprite-listing-main-features/sprite.svg';
import filterSprite from '@pepita-fe/sprite-listing-search/sprite.svg';

import type { BaseEntityWithChildren } from 'src/components/GeographySearch/types';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { CONTRACT } from 'src/constants/typologies';

import type {
  AssociationLookupItem,
  FranchisingLookupItem,
} from 'src/types/homepage';

import type {
  autocompleteConfigType,
  AutocompleteEndpoints,
  categoryConfigType,
  categoryInternationalConfigType,
  categoryResidentialConfigType,
  inputConfigType,
  placeConfigType,
  rangeConfigType,
  selectConfigType,
} from './formConfigTypes';
import { FormFields } from './formConfigTypes';

export const getCategoryInput = (): categoryConfigType => {
  return {
    field: FormFields.CATEGORY,
    icon: { name: 'palaces', spriteUrl: filterSprite },
    type: 'category',
    label: 'lbl_typology', // i18n-extract-keys ["lbl_typology"]
    customClasses: 'hp-searchForm__multiSelect',
    controlCustomClasses: 'hp-searchForm__multiSelectLabel',
  };
};

export const getCategoryResidentialInput =
  (): categoryResidentialConfigType => {
    return {
      field: FormFields.CATEGORY,
      icon: { name: 'palaces', spriteUrl: filterSprite },
      label: 'lbl_typology', // i18n-extract-keys ["lbl_typology"]
      type: 'categoryResidential',
      customClasses: 'hp-searchForm__multiSelect',
      controlCustomClasses: 'hp-searchForm__multiSelectLabel',
    };
  };

export const getCategoryInternationalInput =
  (): categoryInternationalConfigType => {
    return {
      field: FormFields.CATEGORY,
      icon: { name: 'palaces', spriteUrl: filterSprite },
      label: 'lbl_typology', // i18n-extract-keys ["lbl_typology"]
      type: 'categoryInternational',
      customClasses: 'hp-searchForm__multiSelect',
      controlCustomClasses: 'hp-searchForm__multiSelectLabel',
    };
  };

export const getPriceInput = (contractId: CONTRACT): rangeConfigType => {
  const priceRangesConfiguration = [
    CONTRACT.AFFITTO === contractId
      ? {
          condition: { [FormFields.CONTRACT]: [CONTRACT.AFFITTO] },
          min: 200,
          max: 8000,
          step: {
            0: 50,
            1000: 100,
            2000: 500,
          },
        }
      : {
          min: 50000,
          max: 5000000,
          step: {
            0: 10000,
            100000: 20000,
            400000: 50000,
            1000000: 500000,
            2000000: 200000,
            3000000: 500000,
          },
        },
  ];

  return {
    field: FormFields.PRICE,
    icon: { name: 'money', spriteUrl: filterSprite },
    type: 'range',
    label: 'lbl_price', // i18n-extract-keys ["lbl_price"]
    fromName: FormFields.MIN_PRICE,
    toName: FormFields.MAX_PRICE,
    translationKeys: {
      label: 'price_range_label', // i18n-extract-keys ["price_range_label"]
      fromValue: {
        label: 'price_range_min_only', // i18n-extract-keys ["price_range_min_only"]
      },
      toValue: {
        label: 'price_range_max_only', // i18n-extract-keys ["price_range_max_only"]
      },
      fromToValue: 'price_range_min_max_dash', // i18n-extract-keys ["price_range_min_max_dash"]
      value: 'price_range_value', // i18n-extract-keys ["price_range_value"]
      from: 'price_range_label_from', // i18n-extract-keys ["price_range_label_from"]
      to: 'price_range_label_to', // i18n-extract-keys ["price_range_label_to"]
      title: 'price_range_label', // i18n-extract-keys ["price_range_label"]
    },
    shortLabelValues: true,
    conditionType: 'and',
    range: priceRangesConfiguration,
  };
};

export const getSurfaceInput = (): rangeConfigType => {
  return {
    field: FormFields.SURFACE,
    icon: { name: 'size', spriteUrl: mainFeaturesSprite },
    type: 'range',
    label: 'lbl_measurement', // i18n-extract-keys ["lbl_measurement"]
    fromName: FormFields.MIN_SURFACE,
    toName: FormFields.MAX_SURFACE,
    translationKeys: {
      label: 'surface_range_label', // i18n-extract-keys ["surface_range_label"]
      fromValue: {
        label: 'surface_range_min_only', // i18n-extract-keys ["surface_range_min_only"]
      },
      toValue: {
        label: 'surface_range_max_only', // i18n-extract-keys ["surface_range_max_only"]
      },
      fromToValue: 'surface_range_min_max_dash', // i18n-extract-keys ["surface_range_min_max_dash"]
      value: 'surface_range_value', // i18n-extract-keys ["surface_range_value"]
      from: 'surface_range_label_from', // i18n-extract-keys ["surface_range_label_from"]
      to: 'surface_range_label_to', // i18n-extract-keys ["surface_range_label_to"]
      title: 'surface_range_label', // i18n-extract-keys ["surface_range_label"]
    },
    conditionType: 'or',
    range: [
      {
        min: 20,
        max: 1000,
        step: {
          0: 20,
          200: 50,
          400: 100,
        },
      },
    ],
  };
};

export const getLocationInput = (
  onAutoSubmit: (values: BaseEntityWithChildren[] | GeoJSON) => void
): placeConfigType => {
  const geographySearchConfig = getProductConfig('geographySearch');

  return {
    field: FormFields.PLACE,
    type: 'place',
    label: null,
    drawEnabled: geographySearchConfig.drawEnabled,
    distanceSearchEnabled: isFeatureEnabled('LISTINGS_DISTANCE_SEARCH'),
    metroEnabled: isFeatureEnabled('LISTINGS_METRO_SEARCH'),
    regionSearchEnabled: isFeatureEnabled('REGION_SEARCH_ENABLED'),
    countrySearchEnabled: isFeatureEnabled('COUNTRY_SEARCH_ENABLED'),
    internationalEnabled: isFeatureEnabled('INTERNATIONAL_SEARCH_ENABLED'),
    autorefill: false,
    onAutoSubmit,
    customClasses: 'hp-searchForm__item--location',
  };
};

export const getAuctionsLocationInput = (
  onAutoSubmit: (values: BaseEntityWithChildren[] | GeoJSON) => void
): placeConfigType => {
  const geographySearchConfig = getProductConfig('geographySearch');

  return {
    field: FormFields.PLACE,
    type: 'place',
    label: null,
    drawEnabled: geographySearchConfig.drawEnabled,
    distanceSearchEnabled: isFeatureEnabled('LISTINGS_DISTANCE_SEARCH'),
    metroEnabled: isFeatureEnabled('LISTINGS_METRO_SEARCH'),
    regionSearchEnabled: isFeatureEnabled('REGION_SEARCH_ENABLED'),
    countrySearchEnabled: isFeatureEnabled('COUNTRY_SEARCH_ENABLED'),
    internationalEnabled: false,
    autorefill: false,
    onAutoSubmit,
    customClasses: 'hp-searchForm__item--location',
  };
};

export const getAgenciesLocationInput = (
  isFirstElement?: boolean
): placeConfigType => {
  return {
    field: FormFields.PLACE,
    type: 'place',
    label: null,
    drawEnabled: false,
    distanceSearchEnabled: false,
    metroEnabled: false,
    regionSearchEnabled: false,
    countrySearchEnabled: isFeatureEnabled('SEARCH_AGENCIES_COUNTRY'),
    internationalEnabled: isFeatureEnabled('SEARCH_AGENCIES_COUNTRY'),
    customClasses: `hp-searchForm__item--location${
      isFirstElement ? ' is-leftRounded' : ''
    }`,
  };
};

export const getAutocompleteInput = (
  endpointType: AutocompleteEndpoints
): autocompleteConfigType => {
  if (endpointType === 'myHome') {
    return {
      field: FormFields.AUTOCOMPLETE,
      type: 'autocomplete',
      endpoint: 'myHome',
      label: 'appraise_autocomplete_placeholder', // i18n-extract-keys ["appraise_autocomplete_placeholder"]
      customClasses: 'hp-searchForm__item--location is-leftRounded',
    };
  }

  if (endpointType === 'international') {
    return {
      field: FormFields.AUTOCOMPLETE_INTERNATIONAL,
      type: 'autocomplete',
      endpoint: 'international',
      label: 'hp_foreign_houses_form_required_location_placeholder', // i18n-extract-keys ["hp_foreign_houses_form_required_location_placeholder"]
      customClasses: 'hp-searchForm__item--location',
    };
  }

  if (endpointType === 'holiday') {
    return {
      field: FormFields.AUTOCOMPLETE_HOLIDAY,
      type: 'autocomplete',
      endpoint: 'holiday',
      label: 'hp_vacations_form_location_placeholder', // i18n-extract-keys ["hp_vacations_form_location_placeholder"]
      customClasses: 'hp-searchForm__item--location',
    };
  }

  return {
    field: FormFields.AUTOCOMPLETE,
    type: 'autocomplete',
    endpoint: 'national',
    label: 'appraise_autocomplete_placeholder', // i18n-extract-keys ["appraise_autocomplete_placeholder"]
    customClasses: 'hp-searchForm__item--location is-leftRounded',
  };
};

export const getAgenciesSearchInput = (): inputConfigType => {
  return {
    field: FormFields.AGENCY,
    type: 'input',
    label: 'hp_form_agency_name_placeholder', // i18n-extract-keys ["hp_form_agency_name_placeholder"]
    customClasses: 'hp-searchForm__item--input is-leftRounded',
  };
};

export const getAssociationsInput = (
  associations: AssociationLookupItem[]
): selectConfigType => {
  return {
    field: FormFields.AGENCY_ASSOCIATION,
    type: 'select',
    label: 'hp_form_agency_association_label', // i18n-extract-keys ["hp_form_agency_association_label"]
    options: associations,
  };
};

export const getAgencyGroupsInput = (
  franchising: FranchisingLookupItem[]
): selectConfigType => {
  return {
    field: FormFields.AGENCY_GROUP,
    type: 'select',
    label: 'hp_form_agency_group_label', // i18n-extract-keys ["hp_form_agency_group_label"]
    options: franchising,
    capitalize: false,
  };
};
